	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/12/2020 04:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 50px;
  font-style: normal;
}

.flaticon-clock:before { content: "\f100"; }
.flaticon-alarm-clock:before { content: "\f101"; }
.flaticon-hand:before { content: "\f102"; }
.flaticon-piggy-bank:before { content: "\f103"; }
.flaticon-money-bag:before { content: "\f104"; }
.flaticon-worker:before { content: "\f105"; }
.flaticon-employee:before { content: "\f106"; }
.flaticon-business-and-finance:before { content: "\f107"; }
.flaticon-car:before { content: "\f108"; }
.flaticon-writer:before { content: "\f109"; }
.flaticon-play-button:before { content: "\f10a"; }
.flaticon-turbo:before { content: "\f10b"; }
.flaticon-tyre:before { content: "\f10c"; }
.flaticon-car-1:before { content: "\f10d"; }
.flaticon-car-2:before { content: "\f10e"; }
.flaticon-car-repair:before { content: "\f10f"; }
.flaticon-battery:before { content: "\f110"; }
.flaticon-electricity:before { content: "\f111"; }
.flaticon-message:before { content: "\f112"; }
.flaticon-call:before { content: "\f113"; }
.flaticon-placeholder:before { content: "\f114"; }