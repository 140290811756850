  /* (1366x768) WXGA Display */

  @media screen and (min-width: 1366px) and (max-width: 1919px) {

      .hx-contact-img {
          position: relative;
          width: 100%;
          top: 21%;
      }

      .hx-contact-area-s2 .hx-contact-img {
          position: relative;
          width: 100%;
          top: 21%;
          left: 0;
      }

      .hx-hero-style-1 .slick-dots li {
          display: none;
      }
  }

  @media screen and (min-width: 1196px) and (max-width: 1364px) {
      .hx-contact-img {
          position: relative;
          width: 100%;
          top: 21%;
      }

      .hx-contact-area-s2 .hx-contact-img {
          position: relative;
          width: 100%;
          top: 21%;
      }
  }

  /* Normal desktop :992px. */

  @media (min-width: 992px) and (max-width: 1197px) {

      .hx-hero-style-2 .slick-prev,
      .hx-hero-style-2 .slick-next {
          top: 60%;
      }

      .hero .slick-prev,
      .hero .slick-next {
          display: none !important;
      }

      .hx-hero-style-1.hx-hero-style-2 .hx-slide-caption {
          padding-bottom: 0;
          padding-top: 50px;
      }

      .main-menu nav ul li a {
          font-size: 15px;

      }

      .main-menu nav>ul>li:hover>.submenu {
          -webkit-transform: scaleY(1);
          -ms-transform: scaleY(1);
          -o-transform: scaleY(1);
          transform: scaleY(1);
      }

      .main-menu .submenu.submenu-2 {
          left: 0;
      }



      .header-top ul.hx-header-contact {
          text-align: center;
          margin-top: 15px;
      }

      .account_login-area {
          margin-top: 6px;
      }

      .header-top li,
      .hx-header-top-1 li {
          display: inline-block;
          padding: 0px;
          color: #fff;
          padding-right: 13px;
          font-size: 14px;
      }

      .header-social {
          margin-top: 6px;
      }

      .header-top ul.login-r li+li:before {
          left: -8px;
          top: 0px;
      }

      .header-top ul.header-social:before {
          top: -10%;
      }

      .header-top {
          background: #142440;
          padding: 10px 0;
      }

      [class^="flaticon-"]:before,
      [class*=" flaticon-"]:before,
      [class^="flaticon-"]:after,
      [class*=" flaticon-"]:after {
          font-family: Flaticon;
          font-size: 40px;
          font-style: normal;
      }

      .hero.hx-hero-style-1 .hx-slide-caption h2,
      .hero.hx-hero-style-2 .hx-slide-caption h2,
      .hero.hx-hero-style-3 .hx-slide-caption h2 {
          font-size: 46px;
      }

      .hx-service-wrap {
          padding: 35px 26px 40px;
      }

      .hx-pricing-single {
          padding-left: 30px;
      }

      .hx-pricing-text,
      .hx-pricing-text-2 {
          left: 33px;
      }

      .hx-pricing-img img {
          height: 385px;
      }

      .hx-blog-content ul li {
          font-size: 13px;
      }

      .hx-blog-content ul li a {
          font-size: 12px;
      }

      .hx-blog-content h3 {
          font-size: 18px;
          line-height: 30px;
      }

      .hx-blog-content h3 a {
          font-size: 20px!important;
      }

      .hx-contact-sub {
          padding-left: 30px;
          padding-bottom: 11px;
          padding-top: 22px;
          padding-right: 0px;
      }

      .hx-contact-ad {
          background: #e8eed2;
          padding: 20px 24px 0px;
      }

      .hx-site-footer-top h3 {
          font-size: 25px;
      }

      .hx-counter-area .hx-counter-grids .grid,
      .counter-style-2 .hx-counter-grids .grid {
          width: 23%;
      }

      .hx-hero-style-2 .hx-hero-contact {
          bottom: -246px;
      }

      .hx-slide-caption {
          z-index: 19;
          padding-bottom: 0px;
      }

      .hx-header-middle {
          padding: 5px 0;
      }


      .hx-service-area.hx-service-style-2.service-style-3 .hx-service-wrap .hx-service-item {
          padding: 20px 35px 40px;
      }

      .hx-header-style-1 .main-menu nav ul li,
      .hx-header-style-2 .main-menu nav ul li {
          padding: 35px 12px;
      }

      .hx-contact-img {
          position: relative;
          width: 110%;
          top: 24%;
      }

      .hx-contact-area-s2 .hx-contact-img {
          position: relative;
          width: 110%;
          top: 24%;
          left: -20px;
      }

      .account_login-area li:first-child {
          padding-left: 0;
          padding-right: 5px;
      }

      .hx-features-content h3 {
          font-size: 17px;
          margin-top: 0px;
      }

      .hx-features-area .hx-features-content p {
          margin-bottom: 0px;
          line-height: 24px;
      }

      .hx-blog-content ul li {
          display: inline-block;
          padding: 0 7px;
      }

      .hx-latest-section .img-holder {
          padding-right: 7px;
      }

      .hx-site-footer-top .details p {
          line-height: 20px;
          font-size: 14px;
          margin-top: -3px;
      }

      .hx-latest-section .details span {
          font-size: 14px;
          margin-top: 6px;
      }

      .adress-section ul li {
          font-size: 14px;
      }

      .service-section ul li {
          font-size: 14px;
          margin-bottom: 10px;
      }

      .service-section ul li {
          margin-bottom: 4px;
      }

      .hx-site-footer-top p {
          font-size: 14px;
          line-height: 26px;
      }

      .hx-latest-section .post:first-child {
          margin-bottom: 33px;
      }

      .hx-site-footer-bottom-content span {
          font-size: 14px;
      }

      .hx-about-style-2 .video-btn {
          left: 0px;
      }

      .hx-about-style-1 .hx-about-img:before,
      .hx-about-style-2 .hx-about-img:before {
          left: 16px;
          top: 16px;
          width: 93%;
          height: 94%;
      }

      .hx-blog-sidebar .widget {
          padding: 45px 20px;
      }

      .hx-blog-sidebar .recent-post-widget .post h4 a {
          font-size: 14px;
      }

      .hx-contact-gd-wrap {
          padding: 15px 10px;
          margin-bottom: 30px;
      }

      .hx-contact-gd-text h4 {
          font-size: 18px;
      }

      .hx-contact-gd-text span {
          font-size: 14px;
      }

      .hx-service-dt-right .hx-service-dt-s img {
          float: none;
          padding-right: 0px;
          margin-bottom: 30px;
      }

      .pre-btn,
      .nex-btn {
          width: 300px;
      }

      .hx-service-dt-area .hx-contact-sub {
          padding-left: 44px;
      }

      .hx-contact-sub p {
          font-size: 14px;
      }

      .hx-service-dt-area .hx-contact-sub i {
          left: 4px;
          top: 40%;
      }

      .hx-field-content h3 span {
          font-size: 40px;
      }

      .hx-field-content h2 {
          font-size: 30px;
      }

      .hx-service-dt-area .hx-project-section .grid:before {
          left: 6px;
      }

      .hx-pricing-content ul li a.nav-link {
          line-height: 90px;
          height: 98px;
      }

      .hx-blog-area .hx-blog-content ul li {
          padding: 0 8px;
          font-size: 12px!important;
      }

      .hx-hero-style-2 .hx-slide-caption {
          padding-top: 0;
      }

      .hx-work-content h4,
      .hx-work-content-2 h4 {
          margin-top: 5px;
      }
  }

  /* Normal desktop :991px. */

  @media (min-width: 768px) and (max-width: 991px) {
      .hero {
          height: 600px;
      }

      .hero .slide {
          height: 600px;
      }

      .hx-hero-style-2 {
          height: 600px;
      }

      .hx-hero-style-2 .slide {
          height: 600px;
      }

      .hx-hero-style-1 .hx-slide-caption p,
      .hx-hero-style-2 .hx-slide-caption p,
      .hx-hero-style-3 .hx-slide-caption p {
          font-size: 18px;
      }

      .hx-hero-style-1 .hx-slide-caption h2,
      .hx-hero-style-2 .hx-slide-caption h2,
      .hx-hero-style-3 .hx-slide-caption h2 {
          font-size: 45px;
          line-height: 60px;
      }

      .hx-hero-style-1 .hx-slide-caption h2 span,
      .hx-hero-style-2 .hx-slide-caption h2 span,
      .hx-hero-style-3 .hx-slide-caption h2 span {
          padding: 4px 0;
      }

      .hx-hero-style-2 {
          min-height: 600px;
      }

      .hx-hero-style-2 .slide {
          min-height: 600px;
      }

      .hero .slick-prev,
      .hero .slick-next {
          display: none !important;
      }



      .search {
          text-align: center;
          margin-top: 9px;
          margin-left: -18px;
      }

      .header-top li i,
      .hx-header-top-1 li i {
          padding-right: 0;
      }

      .header-top li,
      .hx-header-top-1 li {
          padding: 15px 0px;
          padding-right: 5px;
          font-size: 12px!important;
      }

      .header-social {
          justify-content: flex-end;
      }

      .hx-section-padding {
          padding: 90px 0;
      }
      .team-area .teams--two {
        margin-bottom: 30px;
      }

      .ptb-100-70 {
          padding: 90px 0 60px;
      }

      .blog-right-bar.blog-right-bar-2 .search-widget input {
          max-width: 300px;
          margin-top: 0;
      }

      .blog-right-bar {
          padding-left: 0px;
      }

      .blog-right-bar .search-widget form button {
          left: 40%;
          top: 79%;
          padding: 9px 15px;
      }

      .category-section .posts {
          padding: 10px 0;
          margin-top: 0px;
      }

      .category-section .post {
          padding: 5px 0;
      }

      .blog-right-bar .tag-widget {
          margin-top: 60px;
      }

      .blog-right-bar.blog-right-bar-2 {
          margin-bottom: 60px;
      }

      .blog-right-bar.blog-right-bar-2 .instagram {
          margin-top: 0px;
      }

      .blog-right-bar.blog-right-bar-2 .tag-widget {
          margin-top: 0px;
      }

      .pagination-wrapper.pagination-wrapper-2 {
          margin-top: 0;
      }

      .blog-right-bar.blog-right-bar-2 .search-widget input {
          margin-top: 0;
      }

      .blog-right-bar.blog-right-bar-2 .widget.search-widget {
          margin-top: 0;
      }

      .blog-right-bar.blog-right-bar-2 .catagory-item {
          margin-bottom: 30px;
      }

      .practice-section.resent-section h3 {
          margin-bottom: 10px;
      }

      .blog-left-bar .author-box {
          padding: 35px 40px;
      }

      .blog-left-bar .author-box .author-name {
          font-size: 22px;
      }

      .blog-left-bar .comments-area .comments-title {
          font-size: 22px;
          font-size: 1.375rem;
      }

      .blog-left-bar .comment-respond .comment-reply-title {
          font-size: 22px;
          font-size: 1.375rem;
      }

      .blog-left-bar .comment-respond .comment-reply-title {
          font-size: 22px;
          font-size: 1.375rem;
      }

      .blog-left-bar .comment-respond form input,
      .blog-left-bar .comment-respond form textarea {
          height: 40px;
      }

      .blog-left-bar .comment-respond form textarea {
          height: 150px;
      }

      .team-active .owl-nav {
          display: none;
      }

      .search ul li a i {
          padding-bottom: 0;
      }

      [class^="flaticon-"]:before,
      [class*=" flaticon-"]:before,
      [class^="flaticon-"]:after,
      [class*=" flaticon-"]:after {
          font-family: Flaticon;
          font-size: 40px;
          font-style: normal;
      }

      .hx-site-footer-link {
          padding-left: 0;
      }

      .p-out {
          padding: 15px;
          padding-top: 0;
      }

      .hx-contact-area.contact-style-1 .hx-contact-content {
          padding: 50px 40px 0px 40px;
      }

      .hx-contact-ad {
          margin-bottom: 0;
      }

      .hx-header-middle .hx-account-item h5 {
          font-size: 12px;
          margin-right: 0;
      }

      .hx-header-middle .btn-style a {
          font-size: 12px;
          padding: 10px 9px;
      }

      .hx-header-middle .hx-account-item h5 span {
          font-size: 11px;
          margin-top: 10px;
      }

      .hx-header-middle .hx-account-item .fi:before {
          font-size: 30px;
      }

      .hx-header-middle .hx-account-item {
          padding-left: 10px;
      }

      .hx-header-middle .account_login-area .hx-account-item:first-child {
          padding-left: 13px;
      }

      .hx-header-middle {
          padding: 10px 0;
      }

      .hx-header-middle .hx-account-item i {
          top: -7%;
      }

      .search ul li>ul {
          right: 57px;
      }

      .hx-contact-img {
          width: 100%;
          top: 0;
      }

      .hx-contact-area-s2 .hx-contact-img {
          width: 100%;
          top: 0;
          left: 0;
      }

      .hx-header-contact .hx-account-item:first-child {
          padding-left: 27px;
          margin-right: 15px;
      }

      .hx-header-middle .hx-account-item {
          padding-left: 5px;
      }

      .hx-header-contact .hx-account-item-2 .fi:before {
          position: absolute;
          left: -23%;
      }

      .btn-style a {
          margin-top: 0;
      }

      .slicknav_btn {
          background-color: transparent;
          margin-top: -43px;
          margin-bottom: 0;
      }

      .search ul li>ul {
          right: -175px;
      }

      .search ul li a i {
          margin-top: 0px;
          padding-bottom: 13px;
      }

      .hx-features-content h3 {
          color: #444;
          font-size: 21px;
          margin-top: 2px;
      }

      .col-p {
          padding-left: 15px;
      }

      .hx-features-content h3 {
          color: #444;
          font-size: 18px;
          margin-top: 2px;
      }

      .hx-about-img {
          margin-bottom: 50px;
      }

      .hx-counter-area .hx-counter-grids .grid,
      .counter-style-2 .hx-counter-grids .grid {
          width: 23%;
      }

      .hx-counter-area .hx-counter-grids .grid p,
      .counter-style-2 .hx-counter-grids .grid p {
          font-size: 16px;
      }

      .hx-testimonial-area {
          padding: 50px 0 125px;
      }

      .hx-blog-content {
          padding: 20px 25px 30px;
      }

      .hx-client-area {
          padding: 70px 0;
      }

      .hx-hero-contact {
          display: none;
      }

      .hx-header-style-1 .slicknav_btn,
      .hx-header-style-2 .slicknav_btn {
          background-color: transparent;
          margin-top: -53px;
          margin-bottom: 0;
      }

      .hero.hx-hero-style-1 .hx-slide-caption h2,
      .hero.hx-hero-style-2 .hx-slide-caption h2 {
          font-size: 47px;
      }

      .hx-about-style-1 .hx-about-img,
      .hx-about-style-2 .hx-about-img {
          text-align: left;
          margin-top: 50px;
          margin-bottom: 0;
      }

      .hx-pricing-content ul {
          display: flex;
          margin: 6px 0;
          justify-content: center;
      }

      .hx-pricing-content ul li a.nav-link {
          margin-right: 10px;
          margin-bottom: 0;
          line-height: 92px;
      }

      .hx-pricing-img {
          margin: 40px 0;
      }

      .hx-pricing-text,
      .hx-pricing-text-2 {
          bottom: 27px;
      }

      .hx-team-single:hover .hx-thumb-content {
          left: 50px;
          top: 50px;
      }

      .hx-about-style-2 .video-btn {
          position: absolute;
          left: 0;
          top: 0;
          width: 85%;
      }

      .hx-about-style-1 .hx-about-img:before,
      .hx-about-style-2 .hx-about-img:before {
          left: 15px;
          top: 13px;
          width: 67%;
          height: 95%;
      }

      .hx-pricing-section {
          padding: 80px 0;
          position: relative;
          padding-bottom: 80px;
      }

      .hx-hero-style-2 .slide {
          min-height: 600px;
      }

      .hx-hero-style-2 .hx-slide-caption {
          min-height: 600px;
          padding-top: 180px;
      }

      .hx-contact-grid-area {
          padding-top: 0;
      }

      .hx-service-dt-left {
          max-width: 500px;
          margin: auto;
      }

      .hx-service-dt-area .hx-contact-ad {
          margin-bottom: 30px;
      }

      .hx-service-dt-s p {
          margin-bottom: 14px;
          font-size: 15px;
      }

      .hx-project-section .grid:before {
          left: 5px;
          top: 0%;
          width: 96%;
          height: 95%;
      }

      .hx-project-text h4 {
          font-size: 18px;
      }

      .pre-btn,
      .nex-btn {
          width: 340px;
      }

      .hx-blog-area .hx-blog-content ul li {
          padding: 0 8px;
      }
      .hx-header-style-1 {
          padding-left: 10px;
          padding: 15px 0;
      }

      
  }

  /* small mobile :576px. */

  @media (min-width: 576px) and (max-width: 767px) {

      .hero {
          height: 600px;
      }

      .hero .slide {
          height: 600px;
      }

      .hx-hero-style-1 .hx-slide-caption p,
      .hx-hero-style-2 .hx-slide-caption p,
      .hx-hero-style-3 .hx-slide-caption p {
          font-size: 18px;
      }

      .hx-hero-style-1 .hx-slide-caption h2,
      .hx-hero-style-2 .hx-slide-caption h2,
      .hx-hero-style-3 .hx-slide-caption h2 {
          font-size: 30px;
          line-height: 60px;
      }

      .hx-hero-style-1 .hx-slide-caption span,
      .hx-hero-style-2 .hx-slide-caption span,
      .hx-hero-style-3 .hx-slide-caption span {
          margin-bottom: 0;
          font-size: 25px;
      }

      .hx-hero-style-1 .hx-slide-caption h2 span,
      .hx-hero-style-2 .hx-slide-caption h2 span,
      .hx-hero-style-3 .hx-slide-caption h2 span {
          padding: 6px 10px;
      }

      .hx-hero-style-2 {
          min-height: 600px;
      }

      .hx-hero-style-2 .slide {
          min-height: 600px;
      }

      .hero .slick-prev,
      .hero .slick-next {
          display: none !important;
      }

      .logo {
          margin-top: 0px;
          text-align: center;
      }

      .header-area {
          padding: 15px 0;
      }

      .search ul li a i {
          padding-bottom: 15px;
      }

      .hx-section-padding {
          padding: 80px 0;
      }
      .team-area .teams--two {
        margin-bottom: 30px;
      }
      .team-area {
        padding-bottom: 70px;
      }
      .ptb-100-70 {
          padding: 80px 0 50px;
      }

      .blog-right-bar.blog-right-bar-2 .instagram {
          margin-top: 60px;
          margin-bottom: 60px;
      }

      .blog-right-bar.blog-right-bar-2 {
          padding-right: 0;
      }

      .blog-right-bar.blog-right-bar-2 .category-section {
          padding: 10px 40px 20px;
      }

      .pagination-wrapper-2 {
          margin-top: 0;
      }

      .blog-right-bar.blog-right-bar-2 .widget.search-widget {
          margin-top: 0;
      }

      .blog-right-bar.blog-right-bar-2 .search-widget form button {
          right: 46px;
          top: 67%;
      }

      .account_login-area {
          justify-content: center;
      }

      .header-top ul.header-social,
      .hx-header-top-1 ul.header-social {
          position: relative;
          justify-content: center;
      }

      .header-top ul.header-social:before {
          display: none;
      }

      .header-top {
          padding: 10px 0;
      }

      .search {
          text-align: left;
          margin-top: -4px;
          margin-left: -18px;
      }

      .hx-contact-grid-area {
          padding-top: 0;
      }

      .hx-counter-area .hx-counter-grids .grid,
      .counter-style-2 .hx-counter-grids .grid {
          width: 23%;
      }

      .hx-counter-area .hx-counter-grids .grid h2,
      .counter-style-2 .hx-counter-grids .grid h2 {
          font-size: 35px;
      }

      .hx-counter-area .hx-counter-grids .grid p,
      .counter-style-2 .hx-counter-grids .grid p {
          font-size: 12px;
      }

      .hx-contact-area.contact-style-1 .hx-contact-ad {
          padding: 58px 0 17px;
      }

      .hx-contact-area.contact-style-1 .hx-contact-content {
          margin-bottom: 60px;
      }

      .hx-hero-contact {
          display: none;
      }

      .hx-contact-img {
          width: 100%;
          top: 0;
      }

      .hx-contact-area-s2 .hx-contact-img {
          width: 100%;
          top: 0;
          left: 0;
      }

      .hero .slick-dots li {
          margin: 2px;
      }

      .slick-dots li button:before {
          font-size: 10px;
          line-height: 20px;
          width: 20px;
          height: 20px;
          color: #fff;
          opacity: 1;
      }

      .hero .slick-dots {
          bottom: 20px;
      }

      .header-area.hx-header-style-2 .button {
          margin-left: 0px;
      }

      .search ul li>ul {
          right: 44px;
      }

      .hx-header-contact {
          justify-content: center;
          flex-wrap: wrap;
      }

      .hx-header-contact .hx-account-item {
          position: relative;
          color: #333;
          padding: 10px 13px;
      }

      .hx-header-contact .hx-account-item:first-child {
          padding-left: 80px;
          margin-right: 0;
      }

      .hx-header-contact .hx-account-item .fi:before {
          left: 10%;
      }

      .hx-header-contact .hx-account-item-2 .fi:before {
          left: 10%;
      }

      .hx-account-item-4 {
          text-align: center;
      }

      .hx-header-contact .hx-account-item-4:first-child {
          padding-left: 0px;
          margin-right: 0;
      }

      .slicknav_btn {
          background-color: transparent;
          margin-top: -57px;
          margin-bottom: 0;
      }

      .search ul li>ul {
          right: -137px;
      }

      .search ul li:hover>ul {
          top: 119%;
      }

      .hx-header-style-1 .search ul li:hover>ul,
      .hx-header-style-2 .search ul li:hover>ul {
          top: 210%;
      }

      .search ul li a i {
          margin-top: 0px;
          padding-bottom: 13px;
      }

      .header-area {
          padding: 20px 0 5px;
      }

      .slicknav_nav {
          margin-top: 6px;
      }

      .hx-header-style-1 .slicknav_nav,
      .hx-header-style-2 .slicknav_nav {
          margin-top: 0;
      }

      .hero.hx-hero-style-1 .hx-slide-caption h2,
      .hero.hx-hero-style-2 .hx-slide-caption h2,
      .hero.hx-hero-style-3 .hx-slide-caption h2 {
          font-size: 54px;
      }

      .col-p {
          padding-left: 15px;
      }

      .hx-features-area .hx-features-icon {
          margin-bottom: 30px;
      }

      .hx-about-img {
          margin-bottom: 40px;
      }

      .about-style {
          padding: 90px 0;
      }

      .slick-dotted.slick-slider {
          margin-bottom: 0;
      }

      .hx-testimonial-area {
          padding: 50px 0 125px;
      }

      .hx-client-area {
          padding: 50px 0;
      }

      .hx-site-footer-top .details p {
          line-height: 19px;
          margin-top: 2px;
      }

      .hx-site-footer-adress {
          margin-top: 30px;
      }

      .hx-site-footer-service {
          margin-top: 30px;
      }

      .hx-pricing-content ul {
          display: flex;
          margin: 6px 0;
          justify-content: center;
      }

      .hx-pricing-content ul li a.nav-link {
          margin-right: 10px;
          margin-bottom: 0;
      }

      .hx-pricing-img {
          margin: 40px 0;
      }

      .hx-pricing-text,
      .hx-pricing-text-2 {
          bottom: 27px;
      }

      .hx-team-single:hover .hx-thumb-content {
          left: 80px;
          top: 80px;
      }

      .hx-about-style-1 .hx-about-img,
      .hx-about-style-2 .hx-about-img {
          text-align: left;
          margin-bottom: 0;
          margin-top: 50px;
      }

      .hx-about-style-2 .video-btn {
          left: 0;
      }

      .hx-about-style-1 .hx-about-img:before,
      .hx-about-style-2 .hx-about-img:before {
          left: 16px;
          top: 13px;
          width: 90%;
          height: 95%;
      }

      .hx-pricing-section {
          padding: 80px 0;
          position: relative;
          padding-bottom: 80px;
      }

      .hx-hero-style-2 .slide {
          min-height: 600px;
      }

      .hx-hero-style-2 .hx-slide-caption {
          padding-top: 165px;
      }

      .hx-header-style-1 .search,
      .hx-header-style-2 .search {
          text-align: left;
      }

      .hx-header-style-1 .search ul li>ul,
      .hx-header-style-2 .search ul li>ul {
          right: -117px;
      }
      .hx-header-style-1 .search ul li>ul{
        right: 60px;
      }

      .counter-style-2 {
          padding-top: 30px;
          padding-bottom: 0;
      }

      .hx-service-dt-area .hx-contact-ad {
          margin-bottom: 30px;
      }

      .hx-service-dt-right .hx-service-dt-s img {
          float: none;
          padding-right: 0;
          margin-bottom: 30px;
      }

      .pre-btn,
      .nex-btn {
          width: 250px;
      }

      .hx-project-section .grid:before {
          left: 5px;
          top: 0%;
          width: 96%;
          height: 95%;
      }

      .hx-project-text h4 {
          font-size: 18px;
      }
      .prv-nx p {
            margin-top: 0px;
       }
      .prv-nx {
        text-align: center;
      }
    .hx-header-middle .logo img{
        width: 50%;
      }
      .hx-header-style-1 {
          padding-left: 10px;
          padding: 15px 0;
      }
      .team-area .teams--two__rect {
          width: 800px;
          height: 284px;
      }
      .team-area .teams--two:hover .teams--two__rect {
          top: 70%;
      }
      .team-area .teams--two p {
          top: 88%;
          left: -100%;
      }
      .team-area .teams--two ul {
        bottom: 8px;
        left: 22px;
      }
  }

  /* extra small mobile 320px. */

  @media (max-width: 575px) {
      .hero {
          height: 450px;
      }

      .hero .slide {
          height: 450px;
      }

      .hx-hero-style-2 {
          min-height: 500px;
      }

      .hx-hero-style-2 .slide {
          min-height: 500px;
      }

      .hx-hero-style-1 .hx-slide-caption h2,
      .hx-hero-style-2 .hx-slide-caption h2,
      .hx-hero-style-3 .hx-slide-caption h2 {
          font-size: 35px;
      }

      .hx-hero-style-2 {
          height: 450px;
      }

      .hx-hero-style-2 .slide {
          height: 450px;

      }

      .hero .slick-prev,
      .hero .slick-next {
          display: none !important;
      }

      .logo {
          margin-top: 0px;
      }

      .header-area {
          padding: 20px 0;
      }

      .hx-hero-style-1 .hx-slide-caption span,
      .hx-hero-style-2 .hx-slide-caption span,
      .hx-hero-style-3 .hx-slide-caption span {
          margin-bottom: 0px;
          display: inline-block;
          font-size: 17px;
      }

      .hx-hero-style-1 .hx-slide-caption h2,
      .hx-hero-style-2 .hx-slide-caption h2,
      .hx-hero-style-3 .hx-slide-caption h2 {
          line-height: 37px;
          font-size: 25px;
          margin: 8px 0;
      }

      .hx-hero-style-1 .hx-slide-caption p,
      .hx-hero-style-2 .hx-slide-caption p,
      .hx-hero-style-3 .hx-slide-caption p {
          font-size: 15px;
          margin-bottom: 20px;
      }

      .header-top li {
          display: inline-block;
          padding: 3px 8px;
          color: #fff;
          padding-left: 0;
          font-size: 13px;
      }

      .header-social {
          justify-content: center;
      }

      .header-top ul.hx-header-contact {
          text-align: center;
      }

      .account_login-area {
          text-align: center;
          justify-content: center;
      }

      .btn-style2 {
          text-align: center;
      }

      .hx-header-contact li:first-child:before {
          display: none;
      }

      .hx-section-padding {
          padding: 70px 0;
      }

      .ptb-100-70 {
          padding: 70px 0 40px;
      }

      .blog-left-bar .tag-share .tag a {
          padding: 9px 17px 10px;
      }

      .blog-left-bar .tag-share {
          border-right: 1px solid #ebebf1;
      }

      .blog-left-bar .tag-share .tag,
      .blog-left-bar .tag-share .share {
          display: block;
      }

      .blog-left-bar .tag-share .tag {
          float: none;
      }

      .blog-left-bar .tag-share .share {
          float: none;
          border-top: 1px solid #ebebf1;
          margin-top: 1px;
      }

      .blog-left-bar .author-box {
          padding: 25px;
      }

      .blog-left-bar .author-box .author-avatar {
          float: none;
      }

      .blog-left-bar .author-box .author-content {
          padding: 0;
          margin: 15px 0 0 0;
      }

      .blog-left-bar .more-posts .post-control-link {
          font-size: 14px;
      }

      .blog-right-bar {
          padding-left: 0px;
          margin-top: 30px;
      }

      .blog-left-bar .comments-area .comment.comment-2 {
          padding-left: 0;
      }

      .blog-left-bar .comments-area .comment.comment-5 {
          padding-left: 0;
      }

      .blog-left-bar .more-posts>div {
          width: 50%;
          float: left;
      }

      .blog-left-bar .comments-area .comment-theme {
          position: static;
      }

      .blog-left-bar .comments-area .comment-main-area {
          padding-left: 0;
          margin-top: 25px;
      }

      .blog-left-bar .comment-respond .form-inputs>input:nth-child(2) {
          width: 100%;
          float: none;
      }

      .blog-left-bar .comment-respond .form-inputs>input:nth-child(3) {
          width: 100%;
          float: none;
      }

      .account_login-area {
          justify-content: center;
      }

      .header-top ul.header-social {
          position: relative;
          justify-content: center;
      }

      .header-top ul.header-social:before {
          display: none;
      }

      .header-top ul.login-r {
          text-align: center;
      }

      .header-top li {
          padding: 4px;
      }

      .header-top ul.login-r li a {
          padding: 10px;
      }

      .header-top ul.login-r li+li:before {
          top: 4px;
      }

      .header-top {
          padding: 10px 0;
      }

      [class^="flaticon-"]:before,
      [class*=" flaticon-"]:before,
      [class^="flaticon-"]:after,
      [class*=" flaticon-"]:after {
          font-family: Flaticon;
          font-size: 40px;
          font-style: normal;
      }

      .hx-testimonial-img {
          width: 30%;
      }

      .hx-blog-content ul li {
          font-size: 12px!important;
      }

      .hx-blog-content ul li a {
          font-size: 13px;
      }

      .hx-blog-content h3 {
          font-size: 24px;
          line-height: 28px;
      }

      .hx-blog-content h3 a {
          font-size: 19px;
      }

      .hx-blog-area .hx-blog-content {
          padding: 20px 24px 30px;
      }

      .hx-contact-ad {
          margin-bottom: 30px;
      }

      .hx-contact-sub p {
          font-size: 14px;
      }

      .hx-contact-sub {
          padding-left: 40px;
          padding-bottom: 4px;
          padding-top: 22px;
      }

      .hx-client-area .owl-carousel .owl-item img {
          width: 57%;
      }

      .hx-client-area .owl-carousel .owl-stage-outer {
          padding-left: 30px;
      }

      .hx-client-area {
          padding: 35px 0;
      }

      .footer-b {
          padding-left: 15px;
          margin-top: 30px;
      }

      .hx-site-footer-link {
          padding-left: 0;
          margin-top: 30px;
      }

      .Subscribe.Now {
          margin-top: 30px;
      }

      .hx-site-footer-top {
          padding: 60px 0;
      }

      .hx-site-footer-bottom-content span {
          font-size: 14px;
      }

      .hx-site-footer-bottom-content {
          text-align: center;
      }

      .hx-counter-area .hx-counter-grids .grid,
      .counter-style-2 .hx-counter-grids .grid {
          width: 45%;
      }

      .hx-counter-area .hx-counter-grids .grid h2:before,
      .counter-style-2 .hx-counter-grids .grid h2:before {
          left: 28%;
      }

      .hx-counter-area .hx-counter-grids .grid p,
      .counter-style-2 .hx-counter-grids .grid p {
          font-size: 14px;
      }

      .hx-counter-area .hx-counter-grids .grid h2,
      .counter-style-2 .hx-counter-grids .grid h2 {
          font-size: 36px;
      }

      .cta-area.cta-area-2 {
          padding: 30px 0 0px;
      }

      .breadcumb-wrap h2 {
          font-size: 30px;
          font-weight: 700;
      }

      .hx-blog-content-2 h2 {
          font-size: 20px;
      }

      .blog-right-bar .details p {
          margin-bottom: 0;
          font-size: 13px;
      }

      .blog-right-bar .category-section {
          padding: 40px 30px 20px;
      }

      .hx-header-middle .logo {
          margin-top: 0px;
          text-align: center;
          margin-left: -20px;
      }

      .hx-header-middle .account_login-area {
          justify-content: flex-end;
          flex-wrap: wrap;
      }

      .hx-header-middle .hx-account-item h5 {
          margin-right: 20px;
          text-align: left;
      }

      .hx-header-middle .account_login-area {
          margin-top: 20px;
      }

      .hx-header-middle .account_login-area {
          position: relative;
          max-width: 306px;
          margin: auto;
          margin-top: 30px;
      }

      .header-area.hx-header-style-2 .search ul li>ul,
      .header-area.hx-header-style-2 .search ul li>ul {
          top: 170%;
          left: 50%;
          visibility: hidden;
      }

      .hx-slide-caption {
          padding-bottom: 20px;
          padding-left: 20px;
      }

      .header-area {
          padding: 8px 0;
      }

      .search ul li:hover>ul {
          visibility: visible;
          opacity: 1;
          top: 119%;
      }

      .hero .slick-dots li {
          margin: 2px;
      }

      .slick-dots li button:before {
          font-size: 9px;
          line-height: 18px;
          width: 18px;
          height: 18px;
          color: #fff;
          opacity: 1;
      }

      .hero .slick-dots {
          bottom: 8px;
      }

      .hx-slide-caption .btn-style a {
          padding: 8px 16px;
          font-size: 14px;
      }

      .search ul li>ul {
          right: 0;
      }

      .hx-contact-img {
          position: relative;
          width: 100%;
          top: 0;
      }

      .hx-contact-area-s2 .hx-contact-img {
          position: relative;
          width: 100%;
          top: 0;
          left: 0;
      }

      .search ul li>ul {
          right: 44px;
      }

      .hx-header-contact .hx-account-item {
          position: relative;
          color: #333;
          padding: 10px 13px;
      }

      .hx-header-contact .hx-account-item-2 .fi:before {
          left: -19%;
      }

      .slicknav_btn {
          background-color: transparent;
          margin-top: -49px;
          margin-bottom: 0;
      }

      .search ul li>ul {
          right: -175px;
      }

      .search ul li a i {
          margin-top: 0px;
          padding-bottom: 20px;
      }

      .header-area {
          padding: 20px 0 5px;
      }

      .slicknav_nav {
          margin-top: 6px;
      }

      .hx-header-contact .hx-account-item:first-child {
          padding-left: 70px;
          margin-right: 0;
      }

      .hx-header-middle .hx-account-item h5 {
          margin-right: 0;
          text-align: left;
      }

      .account_login-area {
          justify-content: center;
          flex-wrap: wrap;
      }

      .hx-header-contact {
          max-width: 250px;
          margin: auto;
          margin-top: 15px;
      }

      .hx-header-contact .hx-account-item .fi:before {
          left: 3%;
      }

      .hx-header-contact .hx-account-item-4:first-child {
          padding-left: 60px;
          margin-right: 0;
      }

      .search {
          text-align: left;
          margin-top: 1px;
          margin-left: 13px;
      }

      .col-p {
          padding-left: 15px;
      }

      .hx-features-content h3 {
          font-size: 17px;
          margin-top: 0px;
      }
      .prv-nx p {
            margin-top: 0px;
      }
      .prv-nx {
        text-align: center;
      }
      .hx-features-area .hx-features-icon i.fi {
          margin-left: 0;
      }

      .hx-features-area .hx-features-content p {
          margin-bottom: 0px;
          line-height: 25px;
      }

      .hx-about-img {
          margin-bottom: 30px;
      }

      .about-style {
          padding: 70px 0;
      }

      .about-style .hx-about-content h2 {
          color: #333;
      }

      .hx-site-title h2 {
          font-size: 22px;
          margin-top: 15px;
      }

      .hx-testimonial-area {
          padding: 50px 0 125px;
      }

      .hx-site-footer-top h3 {
          padding-bottom: 15px;
          margin-bottom: 25px;
      }

      .hx-site-footer-adress {
          margin: 30px 0;
      }

      .hx-contact-content h2 {
          font-size: 24px;
          margin-bottom: 29px;
      }

      .hx-hero-contact {
          display: none;
      }

      .header-top ul.header-social,
      .hx-header-top-1 ul.header-social {
          justify-content: center;
      }

      .hx-header-style-1 .logo,
      .hx-header-style-2 .logo {
          text-align: center;
          margin: auto;
      }

      .hx-header-style-1 .search ul li a i,
      .hx-header-style-2 .search ul li a i {
          color: #fff;
      }
    

      .hx-header-style-1 .search ul li:hover>ul,
      .hx-header-style-2 .search ul li:hover>ul {
          top: 210%;
      }

      .hx-header-style-1 .slicknav_nav,
      .hx-header-style-2 .slicknav_nav {
          margin-top: 0;
      }

      .hero.hx-hero-style-1 .hx-slide-caption h2,
      .hero.hx-hero-style-2 .hx-slide-caption h2,
      .hero.hx-hero-style-3 .hx-slide-caption h2 {
          font-size: 26px;
          line-height: 47px;
          margin: 10px 3px 20px;
      }

      .col-m {
          padding-right: 15px;
      }

      .hx-about-style-1 .hx-about-img,
      .hx-about-style-2 .hx-about-img {
          text-align: left;
          margin-bottom: 0;
          margin-top: 30px;
      }

      .about-style,
      .hx-about-style-1,
      .hx-about-style-2 {
          background: #fff;
          padding: 70px 0;
      }

      .hx-site-title h2,
      .hx-site-title-1 h2 {
          font-size: 22px;
      }

      .hx-site-title-1 h2:before {
          left: 33%;
      }

      .hx-service-wrap {
          padding: 35px 10px 40px;
      }

      .hx-pricing-content ul {
          display: flex;
          margin: 6px 0;
          justify-content: center;
      }

      .hx-pricing-content ul li a.nav-link{
          margin-right: 10px;
          margin-bottom: 0;
      }

      .hx-pricing-img {
          margin: 40px 0;
      }

      .hx-pricing-text,
      .hx-pricing-text-2 {
          bottom: 27px;
      }

      .hx-pricing-content ul li a.nav-link{
          width: 70px;
          height: 60px;
          line-height: 45px;
          margin-right: 10px;
          margin-bottom: 0;
      }

      .hx-pricing-text h4 {
          font-size: 20px;
          margin-bottom: 0;
      }

      .hx-pricing-text span {
          font-size: 20px;
      }

      .hx-team-single:hover .hx-thumb-content {
          left: 50px;
          top: 50px;
      }

      .hx-about-style-2 .video-btn {
          left: 0;
      }

      .hx-about-style-1 .hx-about-img:before,
      .hx-about-style-2 .hx-about-img:before {
          left: 16px;
          top: 15px;
          width: 89%;
          height: 90%;
      }

      .hx-site-title-1 h2 {
          margin-bottom: 35px;
      }

      .hx-pricing-section {
          padding: 70px 0;
          position: relative;
          padding-bottom: 80px;
      }

      .hx-hero-style-2 .hx-slide-caption {
          padding-top: 140px;
      }

      .hx-blog-pg-section .entry-meta>li+li {
          margin-left: 4px;
          padding-left: 11px;
          padding-top: 10px;
      }

      .hx-blog-pg-section .format-quote .details:before {
          width: 64px;
          height: 45px;
          right: 2px;
          top: 1px;
      }

      .hx-blog-sidebar .recent-post-widget .post .details {
          padding: 0 0 0 3px !important;
      }

      .hx-blog-sidebar .recent-post-widget .post h4 a {
          font-size: 14px;
      }

      .hx-blog-sidebar .tag-widget ul li a {
          padding: 8px 15px;
      }

      .hx-blog-single-section .comments-area ol ul {
          padding-left: 0;
      }

      .hx-header-style-1 .search,
      .hx-header-style-2 .search {
          text-align: left;
          padding-left: 15px;
      }

      .hx-header-style-1 .search ul li>ul,
      .hx-header-style-2 .search ul li>ul {
          right: -190px;
      }
      .hx-header-style-1 .search ul li>ul{
          right: 0px!important;
      }

      .counter-style-2 {
          padding-top: 30px;
          padding-bottom: 0;
      }

      .hx-about-style-3 {
          padding: 70px 0;
      }

      .hx-contact-grid-area {
          padding-top: 0;
      }

      .hx-contact-gd-wrap {
          padding: 15px 10px;
          margin-bottom: 30px;
      }

      .hx-contact-gd-text h4 {
          font-size: 18px;
      }

      .hx-contact-gd-text span {
          font-size: 14px;
      }

      .hx-service-dt-area .hx-contact-ad {
          margin-bottom: 30px;
      }

      .hx-service-dt-right .hx-service-dt-s img {
          float: none;
          padding-right: 0;
          margin-bottom: 30px;
      }

      .pre-btn,
      .nex-btn {
          width: 290px;
      }

      .pre-btn {
          float: none;
          border-right: 1px solid #eeeeee;
          margin-bottom: 20px;
      }

      .hx-field-content h3 span {
          font-size: 40px;
      }

      .hx-field-content h2 {
          font-size: 30px;
      }

      .hx-service-dt-right blockquote {
          font-size: 18px;
          padding-left: 25px;
      }

      .hx-project-section .grid:before {
          left: 0;
          top: 0%;
          width: 100%;
          height: 94%;
      }

      .hx-project-text h4 {
          font-size: 18px;
      }

      .hx-project-section .hx-project-filters ul>li+li {
          margin-left: 18px;
      }
      .team-area .teams--two {
        margin-bottom: 30px;
      }
      .hx-header-middle .logo img{
        width: 50%;
      }
      .hx-header-style-1 {
          padding-left: 10px;
          padding: 20px 0;
      }
  }

  /* Large Mobile :480px. */
  @media (min-width: 375px) and (max-width: 576px) {
      .team-area .teams--two {
        margin-bottom: 30px;
      }
      .hx-header-contact .hx-account-item:first-child {
          padding-left: 60px;
          margin-right: 0;
      }

      .hx-header-contact .hx-account-item-4:first-child {
          padding-left: 55px;
          margin-right: 0;
      }

      .hx-header-contact .hx-account-item .fi:before {
          left: 0%;
      }

      .hx-header-middle .hx-account-item h5 {
          margin-right: 0;
          text-align: left;
      }

      .account_login-area {
          justify-content: center;
          display: block;
      }

      .col-l {
          padding: 0;
      }

      .hx-header-contact {
          max-width: 250px;
          margin: auto;
      }

      .hx-about-style-1 .hx-about-img:before,
      .hx-about-style-2 .hx-about-img:before {
          left: 17px;
          top: 15px;
          width: 92%;
          height: 93%;
      }

      .hx-team-single:hover .hx-thumb-content {
          left: 50px;
          top: 60px;
      }

      .hx-project-section .grid:before {
          left: 0;
          top: 0%;
          width: 100%;
          height: 96%;
      }

      .hx-header-style-1 .search ul li>ul,
      .hx-header-style-2 .search ul li>ul {
          right: -169px;
      }
     .team-area .teams--two__rect {
          width: 800px;
          height: 284px;
      }
      .team-area .teams--two:hover .teams--two__rect {
          top: 70%;
      }
      .team-area .teams--two p {
          top: 85%;
          left: -100%;
      }
      .team-area .teams--two ul {
        bottom: 8px;
        left: 15px;
      }

  }

  @media(max-width:520px){
    .account_login-area li:first-child {
        padding-left: 0;
        padding-right: 0px;
    }
    .hx-header-style-1 .search ul li:hover>ul, 
    .hx-header-style-2 .search ul li:hover>ul {
        top: 186%;
    }
  }
  @media(max-width:450px){
    .hx-header-style-1 .search ul li:hover>ul, 
    .hx-header-style-2 .search ul li:hover>ul {
        top: 160%;
        right: -19px!important;
    }
  }

  @media(max-width:575px){
    .hx-contact-area form, 
    .hx-contact-area-s2 form, 
    .contact-style-2 form, 
    .contact-style-2-s2 form{
        margin: 0;
    }
  }